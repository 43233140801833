// This is a mixin file using to handle messages and popup confirmations.
export default {
  data() {
    return {
      mixinMessages: [],
      mixinpopups: {},
    };
  },
  methods: {
    displayMessage(
      text: any,
      variant: any,
      position = "top-0 end-0",
      timeoutMs = 10000
    ) {
      const message: any = {
        text,
        variant,
        color: variant == "danger" ? "white" : "dark",
        position,
        timeoutMs,
      };
      message.close = this.closeMessage.bind(message);
      setTimeout(() => message.close(), message.timeoutMs);
      this.$store.commit("showMessage", message);
    },
    closeMessage(messageObj: any) {
      this.$store.commit("removeMessage", messageObj);
    },
    showPopup(data: any) {
      this.mixinpopups = data;
      this.mixinpopups.close = this.closePopup.bind(this);
    },
    confirmYes(id: any) {
      this.$emit("confirm", id);
    },
    closePopup() {
      this.mixinpopups = {};
    },
  },
};
