import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "m-auto col-11" }
const _hoisted_3 = { class: "pb-3" }
const _hoisted_4 = { class: "main-section p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Topnav = _resolveComponent("Topnav")!
  const _component_Main = _resolveComponent("Main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_Topnav)
      ]),
      _createElementVNode("section", _hoisted_4, [
        _createVNode(_component_Main)
      ])
    ])
  ]))
}