
//Messages is reusable component will be used to display response messages and confirmation messages.

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    popupMessage: {
      type: Object,
      default() {
        return {};
      },
    },
  },
});
