<template>
  <div id="footer" class="footer">
    <div class="container">
      <h3>Meraki Facades</h3>
      <p>Create your would through us</p>
      <div class="copyright">
        &copy; Copyright <strong><span>MerakiFacades</span></strong
        >. All Rights Reserved
      </div>
    </div>
  </div>
</template>
