
import { defineComponent } from "vue";
import Topnav from "./Topnav.vue";
import Main from "./Main.vue";
export default defineComponent({
  name: "Layout",
  components: {
    Topnav,
    Main,
  },

  methods: {},
});
