import { createStore } from "vuex";

export default createStore({
  state: {
    page_size: 10,
    dropdown_page_size: 10,
    messages: [{}],
  },
  mutations: {
    showMessage(state, message) {
      state.messages.push(message);
    },
    removeMessage(state, message) {
      const indexOfMessage: any = state.messages.indexOf(message);
      state.messages.splice(indexOfMessage, 1);
    },
  },
  actions: {},
  modules: {},
});
