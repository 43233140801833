import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/website/Home.vue";
import Layout from "../views/website/Layout.vue";
import AppLayout from "../views/app/AppLayout.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/",
        name: "home",
        // route level code-splitting
        // this generates a separate chunk (home.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/website/Home.vue"),
      },
      {
        path: "/get-quote",
        name: "getQuote",
        // route level code-splitting
        // this generates a separate chunk (getQuote.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "getQuote" */ "../views/website/GetQuote.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // route level code-splitting
    // this generates a separate chunk (forgot-password.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/forgot-password.vue"
      ),
  },
  {
    path: "/set-password/:token",
    name: "set-password",
    // route level code-splitting
    // this generates a separate chunk (set-password.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "set-password" */ "../views/auth/set-password.vue"
      ),
  },
  {
    path: "/app",
    name: "app-layout",
    component: AppLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/dashboard.vue"
          ),
      },
      {
        path: "/product-categories",
        name: "product-categories",
        // route level code-splitting
        // this generates a separate chunk (product-categories.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "product-categories" */ "../views/dashboard/product-categories/product-categories-list.vue"
          ),
      },
      {
        path: "/products",
        name: "products",
        // route level code-splitting
        // this generates a separate chunk (products.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/dashboard/products/products-list.vue"
          ),
      },
      {
        path: "/quotes",
        name: "quotes",
        // route level code-splitting
        // this generates a separate chunk (quotes.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "quotes" */ "../views/dashboard/quotes/quotes-list.vue"
          ),
      },
      {
        path: "/cta",
        name: "cta",
        // route level code-splitting
        // this generates a separate chunk (cta.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "cta" */ "../views/dashboard/cta/cta-list.vue"
          ),
      },
      {
        path: "/contact-us",
        name: "contact-us",
        // route level code-splitting
        // this generates a separate chunk (contact-us.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "contact-us" */ "../views/dashboard/contact-us/contact-us-list.vue"
          ),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/website/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token")) {
      next();
      return;
    }
    next("/");
  }
  next();
});

export default router;
