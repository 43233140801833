
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
  data() {
    return {
      routes: [
        { link: "/dashboard", name: "Dashboard", icon: "fas fa-th-large" },
        {
          link: "/product-categories",
          name: "Product Categories",
          icon: "fa fa-list-alt",
        },
        { link: "/products", name: "Products", icon: "fa-solid fa-sitemap" },
        { link: "/quotes", name: "Quotes", icon: "fa-brands fa-quora" },
        { link: "/contact-us", name: "Contact Us", icon: "fa-solid fa-address-card" },
        { link: "/cta", name: "Cta", icon: "fa-solid fa-phone" },
      ],
    };
  },

  mounted() {
    this.selectedMenuItem =
      this.$route.name == "getQuote" ? "" : this.selectedMenuItem;
  },
  methods: {
    scrollDownToElement(className: string, offcanvas = false) {
      this.$router.push("/");
      this.openMenu = false;
      this.selectedMenuItem = className;
      setTimeout(() => this.emitter.emit("scrollToElement", className), 50);
      if (offcanvas) {
        this.$refs.quoteBtn.click();
      }
    },
    getQuote() {
      this.selectedMenuItem = "";
      this.$router.push("/get-quote");
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
});
