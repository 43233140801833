
import { defineComponent } from "vue";
import { RestResources } from "../../rest-resource";
const ApiResources = RestResources();
export default defineComponent({
  name: "Header",
  data() {
    return {
      menuItems: [
        { name: "Home", className: "home" },
        { name: "About", className: "about" },
        { name: "Products", className: "products", dropdown: true },
        { name: "Services", className: "services" },
        { name: "Contact", className: "contact" },
      ],
      selectedMenuItem: "home",
      openMenu: false,
      categories: [],
    };
  },
  mounted() {
    this.getProducts();
    this.selectedMenuItem =
      this.$route.name == "getQuote" ? "" : this.selectedMenuItem;
  },
  methods: {
    async getProducts() {
      let resourceEndPoint = ApiResources.ProductCategories.wrap("/all");
      let result = await resourceEndPoint.get();
      this.categories = result.data;
    },
    scrollDownToElement(className: string, offcanvas = false) {
      this.$router.push("/");
      this.openMenu = false;
      this.selectedMenuItem = className;
      setTimeout(() => this.emitter.emit("scrollToElement", className), 50);
      if (offcanvas) {
        this.$refs.quoteBtn.click();
      }
    },
    getQuote() {
      this.selectedMenuItem = "";
      this.$router.push("/get-quote");
      if (!this.openMenu) {
        this.$refs.quoteBtn.click();
      }
    },
  },
});
