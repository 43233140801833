// Here we will generate list of API resources to make API calls.

import Resource from "rest-resource";
import resourceConfig from "./constants";

export const getListResource = (url = "") => {
  return class listResource extends Resource {
    static endpoint = url;
    static search(query: any) {
      return this.list({ query: { q: query } });
    }
  };
};

const resourcesList: any = {};

resourcesList["AuthResource"] = class extends Resource {
  static endpoint = process.env.VUE_APP_ROOT_API + "/auth";
};

export const generateResources = (token: any) => {
  class BaseResource extends Resource {
    static client = Resource.client.constructor(process.env.VUE_APP_ROOT_API, {
      headers: { "x-token-code": localStorage.getItem("token") || token },
    });
    static search(query: any) {
      return this.list({ query: { q: query } });
    }
  }

  resourceConfig.forEach((element) => {
    const relatedResources: any = {};

    if (element.relatedResource) {
      for (const key in element.relatedResource) {
        relatedResources[key] = resourcesList[element.relatedResource[key]];
      }
    }

    resourcesList[element.resourceName] = class extends BaseResource {
      static endpoint = element.endPoint;

      static validation = element.validations || {};

      static related = relatedResources;

      toString() {
        return this.attributes.name;
      }
    };
  });
};

generateResources(localStorage.getItem("token"));

export const RestResources = () => {
  return { ...resourcesList, getListResource };
};

export default { ...resourcesList, generateResources, RestResources };
