// In this file we need to mention list of resources names and endpoints to create rest resource classes.
const constants: Array<any> = [
  {
    resourceName: "AuthResource",
    endPoint: "/auth",
  },
  {
    resourceName: "Products",
    endPoint: "/products",
  },
  {
    resourceName: "ProductCategories",
    endPoint: "/product-categories",
  },
  {
    resourceName: "Cta",
    endPoint: "/cta",
  },
  {
    resourceName: "Contact",
    endPoint: "/contact-us",
  },
  {
    resourceName: "Quotes",
    endPoint: "/quotes",
  },
];

export default constants;
