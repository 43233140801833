import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "./mixins";
import mitt from "mitt";
import "../src/assets/css/style.scss";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const emitter = mitt();
//vue-app/src/main.js
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app
  .use(store)
  .use(router)
  .mixin(mixins)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
